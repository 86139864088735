<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Monitoring Kehadiran Harian Siswa</h5>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Tanggal Awal</label>
                                    <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="start_date" v-on:change="getByKeywords">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Tanggal Awal</label>
                                    <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="start_date" v-on:change="getByKeywords">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Cari berdasarkan nama</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getByKeywords">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th style="width:20%">Nama Siswa</th>
                                                    <th style="width:15%">Tanggal Presensi</th>
                                                    <th style="width:10%">Jam Masuk</th>
                                                    <th style="width:10%">Jam Pulang</th>
                                                    <th style="width:10%">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>
                                                        {{ item.fullname }}<br/>
                                                        Kelas: {{ item.school_class ? item.school_class : '-' }}
                                                    </td>
                                                    <td>
                                                        {{item.present_date}}
                                                    </td>
                                                    <td>
                                                        {{item.jam_masuk}}
                                                    </td>
                                                    <td>
                                                        {{item.jam_keluar}}
                                                    </td>
                                                    <td>
                                                        <b>
                                                            <span v-if="item.status == 'Terlambat'" class="text-danger">{{ item.status }}</span>
                                                            <span v-if="item.status == 'Ontime'" class="text-success">{{ item.status }}</span>
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>


                                <nav v-if="(!dataFetch && dataList.length > 0)" aria-label="navigation" class="m-t-20">
                                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </nav>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'DailyAttendance',
        metaInfo: {
            title: 'Kehadiran Harian Siswa',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState       : false,
                dataFetch        : false,
                dataList         : [],
                keywords         : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 100,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getByKeywords: function() {
                this.pagination.currentPage = 1;
                this.getData();
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'proctor/attendance/daily';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords        : this.keywords,
                        start_date      : this.start_date,
                        end_date        : this.end_date,
                        page            : this.pagination.currentPage,
                        limit           : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            }
        }
    }
</script>